import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Container } from "react-bootstrap";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import axios from "axios";
import "./ViewElement.css"
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { RefreshContext } from './RefreshContext';

const ViewElement = () => {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);
  const [popupContent, setPopupContent] = useState('');
  const { isAuthenticated } = useContext(AuthContext);
  const { username } = useContext(AuthContext);
  const { refresh } = useContext(RefreshContext);
  const [filterActive, setFilterActive] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get("https://codestock.cloud/api/elements", {
          params: {
            createdBy: username
          }
        })
        const formattedData = result.data.map(item => ({
          ...item,
          date: new Date(item.date).toLocaleString()
        }));
        setData(formattedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [refresh]);

  let uniqueUsers = new Set();
  data.forEach(elemet => {
    uniqueUsers.add(elemet.createdBy);
  })

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };
  const filteredData = data.filter((element) =>
    element.schemeDescription.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const userFilter = () => {
    return filteredData.filter((element) =>
      element.createdBy.toLowerCase().includes(username.toLowerCase())
    );
  };

  const userFilteredData = filterActive ? userFilter() : filteredData;

  const copyCodeToClipboard = (code) => {
    navigator.clipboard.writeText(code);
    setShowCopiedMessage(true);
    setTimeout(() => {
      setShowCopiedMessage(false);
    }, 1000);
  };

  const openPopup = (content) => {
    setPopupContent(content);
  };

  const closePopup = () => {
    setPopupContent('');
  };

  const deleteElement = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this item?");
    if (confirmDelete) {
      try {
        await axios.delete(`https://codestock.cloud/api/delete/${id}`);
        setData(data.filter(item => item._id !== id));
      } catch (error) {
        console.error("Error deleting element:", error);
      }
    }
  };

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <>
    <div className="viewMainDiv">
      <Container className='topbar'>
        <Row className='toprow'>
          <Col xs={9} md={11} xl={11}>
            <input
              className="w-100 p-2 my-3 fs-7 searchBar"
              type="text"
              placeholder="Type to search..."
              value={searchTerm}
              onChange={handleSearch}
            />
          </Col>
          <Col xs={3} md={1} xl={1}>
            <Form.Check
              className='switch'
              type="switch"
              id="custom-switch"
              label="All"
              defaultChecked="true"
              onClick={() => setFilterActive(!filterActive)}
            />
          </Col>
        </Row>
      </Container>
      <div className="popup-background" style={{ display: popupContent ? 'flex' : 'none' }}>
        <div className="popup-content">
          <SyntaxHighlighter style={docco}>
            {popupContent}
          </SyntaxHighlighter>
          <Button style={{ margin: '7px' }} variant="secondary" onClick={closePopup}>Close</Button>
        </div>
      </div>
      {showCopiedMessage && <div className="alert alert-success copyied-alert" role="alert">Code copied to clipboard!</div>}
      {userFilteredData.length < 1 ? "Nothing to show :(" : (
        <Row xs={1} md={2} lg={3} className="gx-0">
          {userFilteredData.map((element, idx) => (
            <Col key={idx}>
              <Card className='card'>
                <div className='head-of-card'>
                {element.createdBy === username ?
                  <Button style={{ width: '16%', margin: '4px' }} variant="outline-danger" onClick={() => deleteElement(element._id)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"></path>
                      <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"></path>
                    </svg>
                  </Button>
                  : null}
                  <h1>#{element.schemeCategory}</h1>
                </div>
                <Card.Body>
                  <SyntaxHighlighter language={`${element.schemeCategory}`} style={docco} className="codeSyntax">
                    {element.schemeContent}
                  </SyntaxHighlighter>
                </Card.Body>
                <Card.Footer className='cardFooter'>
                  <div className="text-muted">{element.schemeDescription}</div>
                </Card.Footer>
                <Card.Footer className='cardFooter'>
                  <small className="text-muted createDate"><i>Created: {element.date} by {element.createdBy}</i></small>
                </Card.Footer>
                <div className="d-flex justify-content-between">
                  <Button style={{ width: '48%', margin: '7px' }} variant="outline-dark" onClick={() => openPopup(element.schemeContent)}>open</Button>
                  <Button style={{ width: '48%', margin: '7px' }} variant="success" onClick={() => copyCodeToClipboard(element.schemeContent)}>copy code</Button>
                </div>
              </Card>
            </Col>
          )).reverse()}
        </Row>
      )}
    </div>
    </>
  );
};

export default ViewElement;
